import React from 'react';
import logo from './logo.svg';
import './App.css';
import Menu from './Menu.js';
import Home from './Home.js';
import TV from './TV.js';
import Catering from './Catering.js';
import Loading from './Loading.js';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Alert from 'react-bootstrap/Alert';
import Image from 'react-bootstrap/Image';
import Nav from 'react-bootstrap/Nav';
import Navbar from 'react-bootstrap/Navbar'
import Carousel from 'react-bootstrap/Carousel';
import Button from 'react-bootstrap/Button';
import ButtonGroup from 'react-bootstrap/ButtonGroup';
import ListGroup from 'react-bootstrap/ListGroup';
import {Animated} from "react-animated-css";
import Overlay from 'react-bootstrap/Overlay';
import OverlayTrigger from 'react-bootstrap/OverlayTrigger';
import Tooltip from 'react-bootstrap/Tooltip';
import FB from './FB-glyph.png';
import IG from './IG_Glyph_Fill.png';
import CALL from './Call-Glyph.png';
import EM from './Email-Glyph.png';
import SunsetLogo from './SunsetLogo.png';
import bolhuisMC from './bolhuisMCSunset.PNG';
import {
  BrowserRouter as Router,
  Switch,
  Route,
  Link
} from "react-router-dom";
import StyledFirebaseAuth from 'react-firebaseui/StyledFirebaseAuth';
import * as firebaseui from 'firebaseui';
import {auth} from './firebase.js';
import firebase from 'firebase';
import { IoLogoInstagram, IoLogoFacebook, IoFastFoodSharp, IoHome, IoRestaurantSharp } from 'react-icons/io5';

import {  IoMdCall, IoMdMail } from "react-icons/io";




class App extends React.Component {

  constructor() {
    super();
    this.state = {
      name: "React",
      isSignedIn: false,
      showHideCart: true,
      showHideAdmin: true,
      showHideContact: false,
      showHideOrder: true,
      showHideLoading: false,
      CartActive: false,
      AdminActive: false,
      OrderActive: true,
      ContactActive: false
    };
    this.uiConfig = {
  // Popup signin flow rather than redirect flow.
  signInFlow: 'redirect',
  // Redirect to /signedIn after sign in is successful. Alternatively you can provide a callbacks.signInSuccess function.
  signInSuccessUrl: '/signedIn',
  // We will display Google and Facebook as auth providers.
  signInOptions: [
    firebaseui.auth.AnonymousAuthProvider.PROVIDER_ID
  ],
    callbacks: {
      // Avoid redirects after sign-in.
      signInSuccessWithAuthResult: () => false
    }
  };


    this.hideComponent = this.hideComponent.bind(this);
  }

  hideComponent(name) {
    switch (name) {
      case "Menu":
        this.setState({ showHideContact: false,
                        showHideCart: false,
                        showHideAdmin: false,
                        showHideOrder: true,
                        CartActive: false,
                        AdminActive: false,
                        OrderActive: true,
                        ContactActive: false });
        break;
      case "Cart":
        this.setState({ showHideCart: true,
                        showHideAdmin: false,
                        showHideContact: false,
                        showHideOrder: false,
                        CartActive: true,
                        AdminActive: false,
                        OrderActive: false,
                        ContactActive: false });
        break;
      case "Admin":
        this.setState({ showHideAdmin: true,
                        showHideCart: false,
                        showHideContact: false,
                        showHideOrder: false,
                        CartActive: false,
                        AdminActive: true,
                        OrderActive: false,
                        ContactActive: false });
        break;
      case "Contact":
        this.setState({ showHideContact: true,
                        showHideCart: false,
                        showHideAdmin: false,
                        showHideOrder: false,
                        CartActive: false,
                        Active: false,
                        OrderActive: false,
                        ContactActive: true });
        break;
      case "Loading":
        this.setState({ showHideContact: false,
                        showHideCart: false,
                        showHideAdmin: false,
                        showHideOrder: false,
                        showHideLoading: true });
        break;
      
    }
  }


  componentDidMount() {
    this.unregisterAuthObserver = auth.onAuthStateChanged(
        (user) => this.setState({isSignedIn: !!user})
    );
  }

  componentWillUnmount() {
    this.unregisterAuthObserver();
  }

  render(){

    const { showHideCart, showHideAdmin, showHideContact, showHideOrder, showHideLoading, CartActive, AdminActive, OrderActive, ContactActive } = this.state;
    
    if (!this.state.isSignedIn) {
        return(<Container fluid className="App-mainbg">
          <Row className="Spacer"/>
      <Row className="Spacer"/>
          <Row>
        <Col>

          <Row className="SocialCont">
            <Image className="TitleLogo" src='https://firebasestorage.googleapis.com/v0/b/sunset-diner-446b4.appspot.com/o/SunsetLogo.png?alt=media&token=220ede21-ea00-4d84-96c9-27a3696fe38d'/>
            <Col>
              <Row className="Social-Icons">
                <p className="FooterTitleFont">Please click the button below to continue:</p>
                <StyledFirebaseAuth uiConfig={this.uiConfig} firebaseAuth={auth}/>
              </Row>
            </Col>
          </Row>
        </Col>
      </Row>
          
    </Container>);
    }


    return (
    <Router>
    <Container  className="TitleLogo" fluid >
 

            <Switch>
              <Route path="/tv">
                <TV />
              </Route>
              <div>
               <Navbar className="FooterLeft" fixed="top">
      
      <Col >
      <Row className="Profiles">
      <Col className="MenuCol">
      <OverlayTrigger
                  placement="bottom"
                  delay={{ show: 250, hide: 250 }}
                  overlay={<Tooltip id="button-tooltip">
                              Home
                            </Tooltip>}
                  >
        
          <Button variant="link" active={OrderActive} onClick={() => this.hideComponent("Menu")} style={{padding: '5px', width: true, 'textDecoration': 'none'}}><Link to="/" style={{'textDecoration': 'none'}} className="text-cat"><IoHome width={50} height={50} className="btn-primary3"/><p className="text-yellow">Home</p></Link></Button>

        </OverlayTrigger>
        </Col>
        <Col className="MenuCol">
        <OverlayTrigger
                  placement="bottom"
                  delay={{ show: 250, hide: 250 }}
                  overlay={<Tooltip id="button-tooltip">
                              View Our Online Menu
                            </Tooltip>}
                  >
        
          <Button variant="link" active={OrderActive} onClick={() => this.hideComponent("Menu")} style={{padding: '5px', width: true, 'textDecoration': 'none'}}><Link to={{ pathname:"https://order.tbdine.com/pickup/34012"}} target="_blank" className="text-cat"><IoFastFoodSharp width={50} height={50} className="btn-primary3"/><p className="text-yellow">Menu</p></Link></Button>
        </OverlayTrigger>
        </Col>
        <Col className="MenuCol">
        <OverlayTrigger
                  placement="bottom"
                  delay={{ show: 250, hide: 250 }}
                  overlay={<Tooltip id="button-tooltip">
                              Catering
                            </Tooltip>}
                  >
          <Button variant="link" active={CartActive} onClick={() => this.hideComponent("Cart")} style={{padding: '5px', width: true, 'textDecoration': 'none'}}><Link  to="/catering" style={{'textDecoration': 'none'}} className="text-cat"><IoRestaurantSharp width={50} height={50} className="btn-primary3"/><p className="text-yellow">Catering</p></Link></Button>
        </OverlayTrigger>
        </Col>
        </Row>
      </Col>

    </Navbar>

          <Row className="SocialCont">
            <Col style={{padding: 0}}>
              <Route path="/catering">
                <Catering />
              </Route>
              <Route path="/our-menu">
                <Menu />
              </Route>
              
              <Route exact path="/">
                <Home />
              </Route>
            

            
              <br/>
              <Row>
              <Col xs="4" className="Profiles3">
                <Row className="Profiles3">
                <Col>
                  <OverlayTrigger
                              placement="top"
                              delay={{ show: 250, hide: 250 }}
                              overlay={<Tooltip id="button-tooltip">
                                          Follow our Facebook for the latest news
                                        </Tooltip>}
                              >
                      <Button target="_blank" href="https://www.facebook.com/thesunsetdiner/" variant="link"><IoLogoFacebook width={25} height={25} className="btn-icon-black"/></Button>
                  </OverlayTrigger>
                  <OverlayTrigger
                              placement="top"
                              delay={{ show: 250, hide: 250 }}
                              overlay={<Tooltip id="button-tooltip">
                                          Check out our Instagram for pics
                                        </Tooltip>}
                              >
                      <Button target="_blank" href="https://www.instagram.com/sunsetdiner/" variant="link"><IoLogoInstagram width={25} height={25} className="btn-icon-black"/></Button>
                  </OverlayTrigger>
                  </Col>
                  <Col>
                  <OverlayTrigger
                              placement="top"
                              delay={{ show: 250, hide: 250 }}
                              overlay={<Tooltip id="button-tooltip">
                                          Questions? Give us a call at 519-284-1040
                                        </Tooltip>}
                              >        
                          <Button variant="link" href="tel: +15192841040" ><IoMdCall width={25} height={25} className="btn-icon-black"/></Button>
                  </OverlayTrigger>
                  <OverlayTrigger
                              placement="top"
                              delay={{ show: 250, hide: 250 }}
                              overlay={<Tooltip id="button-tooltip">
                                          Problems? Send us a message at sunsetdiner@gmail.com
                                        </Tooltip>}
                              >
                          <Button variant="link" href="mailto: sunsetdiner@gmail.com" ><IoMdMail width={25} height={25} className="btn-icon-black"/></Button>
                  </OverlayTrigger>
                  </Col>
                  </Row>
              </Col> 
              <Col xs="4">
                <Row className="Profiles">
                  <p className="text-black">343 QUEEN STREET WEST, ST. MARYS, ONTARIO, CANADA</p>
                </Row>
               </Col> 
              <Col xs="4">
                <Row>
                  <Col className="Profiles3">
                    <Row className="Spacer"/>
                    <h5 className="FooterTitleFont">Created by</h5>
                  
                    <Image height={'70vmin'} width={'70vmin'} src={bolhuisMC}/>
                  </Col>
                </Row>
                
              </Col>
              </Row>
              <br/>
              
              {showHideLoading && (<Loading state={this.state}/>)}

            </Col>
          </Row>
          </div>
          </Switch>
          
          </Container>
          </Router>
    );
  }
}

export default App;
