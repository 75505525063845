import React from 'react';
import logo from './logo.svg';
import './App.css';
import {
  BrowserRouter as Router,
  Switch,
  Route,
  Link
} from "react-router-dom";
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Alert from 'react-bootstrap/Alert';
import Image from 'react-bootstrap/Image';
import bolhuisMC from './bolhuisMCSunset.PNG';
import Nav from 'react-bootstrap/Nav';
import Navbar from 'react-bootstrap/Navbar';
import NavDropdown from 'react-bootstrap/NavDropdown';
import Carousel from 'react-bootstrap/Carousel';
import Button from 'react-bootstrap/Button';
import ButtonGroup from 'react-bootstrap/ButtonGroup';
import FB from './FB-glyph.png';
import CALL from './Call-Glyph.png';
import EM from './Email-Glyph.png';
import {Animated} from "react-animated-css";
import {AnimatedOnScroll} from "react-animated-css-onscroll";
import Overlay from 'react-bootstrap/Overlay';
import OverlayTrigger from 'react-bootstrap/OverlayTrigger';
import Tooltip from 'react-bootstrap/Tooltip';

import { CgMenuCheese } from "react-icons/cg";
import { withRouter } from 'react-router-dom';
import Card from 'react-bootstrap/Card';
import SunsetLogo from './SunsetLogo.png';
import { BsSpeaker } from "react-icons/bs";
import { FaChurch, FaGlassCheers, FaUsers } from "react-icons/fa";
import { IoTicketSharp, IoBriefcase, IoHeartHalf, IoBusinessSharp, IoAmericanFootball } from 'react-icons/io5';

class Catering extends React.Component {

  constructor() {
    super();
    this.state = {
      name: "Catering",
      showHideHome: true,
      showHideAboutUs: false,
      showHideContact: false,
      showHideServices: false,
      HomeActive: true,
      AboutUsActive: false,
      ServicesActive: false,
      ContactActive: false
    };
    this.hideComponent = this.hideComponent.bind(this);
  }

  hideComponent(name) {
    console.log(name);
    switch (name) {
      case "Home":
        this.setState({ showHideHome: true,
                        showHideAboutUs: false,
                        showHideContact: false,
                        showHideServices: false,
                        HomeActive: true,
                        AboutUsActive: false,
                        ServicesActive: false,
                        ContactActive: false });
        break;
      case "AboutUs":
        this.setState({ showHideAboutUs: true,
                        showHideHome: false,
                        showHideContact: false,
                        showHideServices: false,
                        HomeActive: false,
                        AboutUsActive: true,
                        ServicesActive: false,
                        ContactActive: false });
        break;
      case "Contact":
        this.setState({ showHideContact: true,
                        showHideHome: false,
                        showHideAboutUs: false,
                        showHideServices: false,
                        HomeActive: false,
                        AboutUsActive: false,
                        ServicesActive: false,
                        ContactActive: true });
        break;
      case "Services":
        this.setState({ showHideContact: false,
                        showHideHome: false,
                        showHideAboutUs: false,
                        showHideServices: true,
                        HomeActive: false,
                        AboutUsActive: false,
                        ServicesActive: true,
                        ContactActive: false });
        break;
    }
  }

  componentDidMount() {
    window.scrollTo(0, 0);
  }


  render(){

    const { showHideHome, showHideAboutUs, showHideContact, showHideServices, HomeActive, AboutUsActive, ServicesActive, ContactActive } = this.state;
    return (
    <Container fluid className="App-mainbg2">
    <Animated animationIn="fadeIn" animationOut="fadeOut" animationInDuration={3000} animationOutDuration={3000} isVisible={showHideHome}>
      <Row>
        <Col>

          <Row className="CateringCont">
            <Col className="Main-card-bg">
<br/>
<br/>
<br/>
<br/>
                <Image src={SunsetLogo} className="TitleLogo"/>
                <br/>
                <p className="TitleFont2">
                  THE SUNSET CATERS
                </p>
                <br/>
                
                <Button className="btn-order" target="_blank" href="mailto: sunsetdiner@gmail.com"> Book Now </Button>

                
               <br/>
              <br/>
            </Col>
          </Row>
        </Col>
      </Row>
         </Animated>
         <Row className="checker-cont">
         </Row>
         <Row className="checker-cont2">
         </Row>
         <Animated animationIn="fadeIn" animationOut="fadeOut" animationInDuration={3000} animationOutDuration={3000} isVisible={showHideHome}>
      <Row className="Card-body" style={{'textAlign': 'center'}}>
      
        <Col  xs>
          <Container fluid className="App-mainbg2">
          <br/>
          <br/>
          <p className="text-subtitle">
                  Bring a taste of the Sunset Diner to your next event!
                </p>
                <p className="text-subtitle">
                  No matter what the size The Sunset Caters will work with you 
                  from start to finish to plan your perfect occasion!
                </p>
                <br/>
          <br/>
           
            </Container>    
        </Col>
      </Row>
      <Row className="Card-body">
      
        <Col  xs>
          <Container fluid className="App-mainbg2">
          <h1 className="text-subtitle" style={{'borderBottom':'2px solid rgba(255,233,0,1)','fontSize': '50px', 'paddingBottom':'25px', 'paddingTop':'25px'}}>The Sunset Shack</h1>
          
          <br/>
          <AnimatedOnScroll animationIn="fadeIn" animationOut="fadeOut">
        
            <Image className="CarouselImg2" rounded src='https://firebasestorage.googleapis.com/v0/b/sunset-diner-446b4.appspot.com/o/Shack2.jpeg?alt=media&token=bf1b0d85-2f6e-4ecd-8a37-7562a1411a00'/>
                   
               
            <Image className="CarouselImg2" rounded src='https://firebasestorage.googleapis.com/v0/b/sunset-diner-446b4.appspot.com/o/Shack3.jpeg?alt=media&token=324686b3-2771-44e7-a018-eba9706c76e0'/>
                   
               
        </AnimatedOnScroll>
           <br/>

            <AnimatedOnScroll animationIn="fadeIn" animationOut="fadeOut">
            <Row >
            
              <Col md={6} sm={12}>
                <Card className="Card-bg">
                
                <IoBusinessSharp width={50} height={50} className="Card-icon"/>

                  <Card.Body className="Card-body">
                    <Card.Title><h1>Workplace Catering</h1></Card.Title>
                    <Card.Text className="Card-txt">
                      Employee Appreciation, Client Appreciation, Holiday Parties, Retail Events
                    </Card.Text>
                  </Card.Body>
                </Card>
              </Col>
              <Col md={6} sm={12}>
                <Card className="Card-bg">
                <IoTicketSharp width={50} height={50} className="Card-icon"/>
                 
                  <Card.Body className="Card-body">
                    <Card.Title><h1>Entertainment Events</h1></Card.Title>
                    <Card.Text className="Card-txt">
                      Sporting Events, Music Festivals, Arena Concerts, Marathons, Fun Runs
                    </Card.Text>
                  </Card.Body>
                </Card>
              </Col>
            </Row>
            </AnimatedOnScroll>
            <br/>
            <AnimatedOnScroll animationIn="fadeIn" animationOut="fadeOut">
            <Row>
              <Col md={6} sm={12}>
                <Card className="Card-bg">
                <IoAmericanFootball width={50} height={50} className="Card-icon"/>
               
                  <Card.Body className="Card-body">
                    <Card.Title><h1>Community Events</h1></Card.Title>
                    <Card.Text className="Card-txt">
                      Street Fairs, Flea Markets, Little League Games, Car Shows, Religious Congregations, Highschool Games
                    </Card.Text>
                  </Card.Body>
                </Card>
              </Col>
              <Col md={6} sm={12}>
                <Card className="Card-bg">
                  <IoBriefcase width={50} height={50} className="Card-icon"/>
                  <Card.Body className="Card-body">
                    <Card.Title><h1>Professional Events</h1></Card.Title>
                    <Card.Text className="Card-txt">
                      Real Estate Open Houses, Conferences, Conventions, Expos, Trade Shows
                    </Card.Text>
                  </Card.Body>
                </Card>
              </Col>
            </Row>
            </AnimatedOnScroll>
            <br/>
            <AnimatedOnScroll animationIn="fadeIn" animationOut="fadeOut">
            <Row>
              <Col md={6} sm={12}>
                <Card className="Card-bg">

                  <FaGlassCheers width={50} height={50} className="Card-icon"/>
                  <Card.Body className="Card-body">
                    <Card.Title><h1>Private Parties</h1></Card.Title>
                    <Card.Text className="Card-txt">
                      Birthdays, Buck & Doe’s, Holidays, Graduations, Anniversary, Baby Shower, House Warming
                    </Card.Text>
                  </Card.Body>
                </Card>
              </Col>
              <Col md={6} sm={12}>
                <Card className="Card-bg">
                  <IoHeartHalf width={50} height={50} className="Card-icon"/>
                  <Card.Body className="Card-body">
                    <Card.Title><h1>Weddings</h1></Card.Title>
                    <Card.Text className="Card-txt">
                      Wedding Receptions, Rehearsal Dinners, Late Night Snacks, Day-After Brunches
                    </Card.Text>
                  </Card.Body>
                </Card>
              </Col>
            </Row>
            </AnimatedOnScroll>
            <br/>
            <AnimatedOnScroll animationIn="fadeIn" animationOut="fadeOut">
            <Row>
              <Col>
                <Card className="Card-bg">
                  <FaUsers width={50} height={50} className="Card-icon"/>
                  <Card.Body className="Card-body">
                    <Card.Title><h1>& Many More!</h1></Card.Title>
                    <Card.Text className="Card-txt">
                      Reach out to us to bring the Sunset Shack to your next event!
                    </Card.Text>
                  </Card.Body>
                </Card>
              </Col>
            </Row>
            </AnimatedOnScroll>
            <AnimatedOnScroll animationIn="fadeIn" animationOut="fadeOut">
            <Row>
              <Col>
               <Card className="Card-bg">
              <Button className="btn-order" target="_blank" href="mailto: sunsetdiner@gmail.com"> Book Now </Button>

              </Card>
              </Col>
            </Row>
            </AnimatedOnScroll>
            <br/>
          </Container>    
        </Col>
      </Row>
      </Animated>
      <Row className="checker-cont">
         </Row>
         <Row className="checker-cont2">
         </Row>
    </Container>
    );
  }
}

export default withRouter(Catering);

